import React from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import Header from '../components/Header';
import Logo from '../assets/YAIR_LOGO.svg';

const ns = 'contact-page';

const ContactPage = ({ location, data }) => {
  const {
    contact: {
      frontmatter: {
        email,
        social_label,
        social_url,
      },
    },
  } = data;

  const rootClassnames = classNames({
    [`${ns}`]: true,
  });

  return (
    <Layout location={location} title="YAIR">
      <Seo title="CONTACT" />
      <div className={rootClassnames}>
        <Header />
        <div className={`${ns}__content`}>
          {social_label && social_url && (
            <a href={social_url} rel="noreferrer">{social_label}</a>
          )}
          {email && (
            <a href={`mailto:${email}`}>{email}</a>
          )}
        </div>
        <div className={`${ns}__logo`}>
          <div className={`${ns}__logo-container`}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
        </div>
      </div>
    </Layout>

  );
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    contact: markdownRemark(id: { eq: $id }) {
      frontmatter {
        email
        social_label
        social_url
      }
    }
  }
`;

ContactPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};
